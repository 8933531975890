.social-button{
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 1rem;
  a{
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid #efefef;
    border-radius: .75rem;
  }
}
