@import "../../../styles/variables";
.cd-top{
  width: 100%;
  height: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .cd-image{
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .cd-back{
    position: absolute;
    top:16px;
    left: 16px;
    svg{
      fill: $color-primary;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .cd-top-content {
    width: 100%;
    padding: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    color: white;
    margin-bottom: 10%;
    span{
      line-height: 0;
    }
  }
}
