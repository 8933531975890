@import "../../../styles/variables";
.cd-bottom-container{
  position:fixed;
  width: 100%;
  height: $bottom-nav-height;
  display: flex;
  //box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.15);
  bottom:0;
  left: 0;
  background-color: $color-white;
  .cd-bottom-content{
    width: 100%;
    height: inherit;
    padding: 0 16px;
    display: flex;
    align-items: center;
    flex:1;
  }
}
