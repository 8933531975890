@import "../../styles/variables";
.pm-list{
  gap: 1rem;
  .pm-list-item{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 100%;
    border-radius: 10px;
    padding: 1rem 1rem;
    .pm-info{
      gap: 1rem;
      img{
        width: 2rem;
      }
    }
    svg{
      fill: $color-primary;
    }
  }
}
