.or-wrapper{
  display: flex;
  width: 100%;
  border-bottom: 1px solid #EEEEEE;
  position: relative;
  height: 1px;
  .or-container{
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    top:-.75rem;
    .or-content{
      padding: 0 1rem;
      background: #FFFFFF;
      color: #616161;
      font-weight: bold;
    }
  }
}
