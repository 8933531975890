@import "../../../styles/variables";
.header{
  width: 100%;
  height: 2.5rem;
  display: flex;
  margin-bottom: 1rem;
}
.header-dashboard{
  justify-content: space-between;
  align-items: center;
  .logo{
    height: 2.5rem;
  }
  .icon-notification{
    svg{
      fill: $color-primary;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
.header-common{
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  height: 3.5rem;
  margin-bottom: 0;
  padding: 16px;
  justify-content: normal;
  align-items: center;
  .icon-back{
    svg{
      width: 1rem;
      height: 1rem;
    }
    margin-right: 1.5rem
  }
  h4.header-title{

  }
}
