@import "../../../styles/variables";
.category-list{
  display: flex;
  gap: 1rem;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 0.75rem;
  .category-list-item{
    cursor: pointer;
    display: inline-block;
    border-radius: 30px;
    border: 2px solid $color-primary;
    color: $color-primary;
    padding: 0.35rem 0.75rem;
    white-space: nowrap;
    font-size: 0.75rem;
    &.active{
      background-color: $color-primary;
      color: $color-white;
    }
  }
}
