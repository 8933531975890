.pdf-pagination{
  display: flex;
  justify-content: center;
  width: 100%;
  .pdf-pagination-content{
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: 700;
    display: flex;
    button{
      padding: 12px;
      box-shadow: none;
      border: none;
      outline: none;
      outline: 0;
      background: #ffffff;
      font-size: 14px;
      font-weight: 700;
    }
    div.page-info{
      padding: 12px;
    }
  }

}
.pdf-container {
  margin: 1em 0;
}

.pdf-container .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-container .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.pdf-container .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.pdf-container .react-pdf__message {
  padding: 20px;
  color: white;
}
