@import "variables";
.avatar-wrapper{
  img{}
}
.course-category-label{
  background-color: $color-light;
  color: $color-primary;
}
.fill-primary{
  fill: $color-primary;
}
.fill-secondary{
  fill: $color-secondary;
}
