@import "../../styles/variables";
.cd-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  .cd-content{
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .cd-content-info{
      font-size: 0.75rem;
      .course-category-label{
        font-size: 0.75rem;
      }
    }
    .cd-nav{
      .nav-item{
        .nav-link{
          color: #25252590;
        }
        &.active{
          .nav-link{
            font-weight: 700;
            color: $color-primary;
          }
          border-bottom: 1px solid $color-primary;
        }
      }
    }
    .cd-tab-content{
      padding: 16px 0;
    }
  }
  &.cd-with-bottom{
    padding-bottom: $bottom-nav-height;
  }
}
