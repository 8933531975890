#home{
  height: calc(100vh - 32px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  .header-home{
    margin-bottom: 0;
  }
  .logo{
    height: 64px;
  }
  .button-start{
    width: 50%;
  }
}
