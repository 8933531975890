@import '../../../styles/variables';
.card-competency{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  //min-height: 150px;
  border-radius: 30px;
  margin: .5rem 0;
  padding: 1rem;
  .cc-content{
    width: 100%;
    display: flex;
    .cc-image{
      width: 100px;
      height: 100px;
      border-radius: 30px;
      margin-right: .75rem;
      img{
        width: inherit;
        height: inherit;
        border-radius: 30px;
        object-fit: cover;
        object-position: center;
      }
    }
    .cc-content-info{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .cc-title{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 1.25rem;
      }

      .cc-duration{
        margin-right: 0.5rem;
      }
      .cc-questions{
        margin-right: 0.5rem;
      }
    }
  }
}
