@import "./../../../styles/variables";
.header-auth{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 4.57rem;
  width: 100%;
  margin-bottom: 3rem;
  a.back{
    position: absolute;
    left: 0;
    top: 0;
    height: inherit;
    display: flex;
    align-items: center;
  }
  img{
    height: 2.28rem;
  }
}
