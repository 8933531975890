@import "../../../styles/variables";
.rating-filter-list{
  display: flex;
  gap: 1rem;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 0.75rem;
  .rating-filter-item{
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 30px;
    border: 2px solid $color-primary;
    color: $color-primary;
    padding: 0.35rem 0.75rem;
    white-space: nowrap;
    font-size: 0.75rem;
    font-weight: bold;
    .rating-filter-icon{
      fill: $color-primary;
    }
    &.active{
      background-color: $color-primary;
      color: $color-white;
      .rating-filter-icon{
        fill: $color-white;
      }
    }
  }
}
