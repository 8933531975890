.material-list{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  .material-item{
    display: flex;
    width: 100%;
    min-height: 4rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 30px;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    .material-item-left{
      display: flex;
      width: 100%;
      .material-item-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        margin-right: 1rem;
      }
    }
    .material-item-info{
      display: flex;
      width: 100%;
      flex-direction: column;
      .material-info-duration{
        font-size: 0.75rem;
      }
    }
    .material-item-right{
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      width: 3rem;
    }
  }
}
