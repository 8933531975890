.cd-member img {
  border-radius: 50%;
  position: relative;
  left: -5px;
  margin-left: -25px;
  z-index: 1;
  width: 3rem;
  height: 3rem;
}
.cd-members {
  direction: rtl;  /* This is to get the stack with left on top */
  text-align: left;  /* Now need to explitly align left */
  padding-left: 25px;  /* Same value as the negative margin */
}
