@import "variables";
.main-content{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: center;
  padding:16px;
  &.with-bottom{
    padding-bottom: $bottom-nav-height;
  }
}
