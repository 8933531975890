@import "../../styles/variables";
.bottom-nav-container{
  position:fixed;
  width: 100%;
  height: $bottom-nav-height;
  display: flex;
  //box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.15);
  bottom:0;
  left: 0;
  background-color: #ffffff;
  .bottom-nav-content{
    width: 100%;
    height: inherit;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex:1;
    .bottom-nav-button{
      background: transparent;
      border: none;
      outline: none;
      outline: 0;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      align-items: center;
      width: 25%;
      transform: scale(1);
      transition: transform 0.2s ease-in-out;
      svg{
        width: 1.5rem;
        height: 1.5rem;
      }
      span{
        font-size: 10px;
      }
      &.active{
        transform: scale(1.2);
        svg{
          fill: $color-primary;
        }
        span{
          color: $color-primary;
        }
      }
    }
  }
}
