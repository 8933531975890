.profile-header{
  .profile-header-image{
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
}
.list-menu{
  .list-menu-item{
    padding-left: 0;
    padding-right: 0;
    border: none;
  }
}
