@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import "variables";
@import "~bootstrap/scss/bootstrap";
//@import "reset";
@import "common";
@import "content";
@import "buttons";
@import "forms";
@import "text";
@import "icons";

body {
  font-family: 'Montserrat',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  background: #ffffff;
  color: $color-dark;
  font-size: calc(0.75em + 1vmin);
}

.main-wrapper{
  min-height: 100vh;
  width: 1080px;
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
}
a{
  text-decoration: none;
  color: $color-dark;
}
.fs-7{
  font-size: 0.75rem !important;
}
.pb-6{
  padding-bottom: 4rem !important;
}
.edge-infinite{
  opacity: 0;
  display: flex;
  width: 100%;
  height: 1px;
  background: #ffffff;
  bottom: 0;
  margin-top: -40px;
}
.video-js{
  height: 100%;
}
.cursor-pointer{
  cursor: pointer;
}

.edge-infinite{
  opacity: 0;
  display: flex;
  width: 100%;
  height: 1px;
  background: #ffffff;
  bottom: 0;
  margin-top: -40px;
}
