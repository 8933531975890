.header-finish{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  color: #ffffff;
  font-weight: 600;
  &.success{
    background: #21E4CF;
  }
  &.failed{
    background: #E42175;
  }
}
.payment-container{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 16px 16px;
  svg.icon-payment{
    &.success{
      fill: #21E4CF;
    }
    &.failed{
      fill: #E42175;
    }
  }
}
