@import "../../styles/variables";
.status-list{
  display: flex;
  gap: 1rem;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 0.75rem;
  .status-list-item{
    cursor: pointer;
    display: inline-block;
    border-radius: 30px;
    border: 2px solid $color-primary;
    color: $color-primary;
    padding: 0.35rem 0.75rem;
    white-space: nowrap;
    font-size: 0.75rem;
    &.active{
      background-color: $color-primary;
      color: $color-white;
    }
  }
}
.card-course{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  //min-height: 150px;
  border-radius: 30px;
  margin: .5rem 0;
  padding: 1rem;
  .cc-content{
    width: 100%;
    display: flex;
    .cc-image{
      width: 100px;
      height: 100px;
      border-radius: 30px;
      margin-right: .75rem;
      img{
        width: inherit;
        height: inherit;
        border-radius: 30px;
        object-fit: cover;
        object-position: center;
      }
    }
    .cc-content-info{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .cc-title{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 1.25rem;
      }
      .cc-organization{
        font-size: .75rem;
      }
      .cc-price{
        color: $color-primary;
        font-size: 1.25rem;
      }
      .cc-rating{
        margin-right: 0.5rem;
        fill: $color-secondary;
      }
      .cc-topic{
        margin-right: 0.5rem;
      }
    }
  }
}

.custom-btn{
  padding: .5rem .75rem;
}
