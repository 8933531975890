@import "variables";
.btn{
  border-radius: 999px;
  padding: 0.75rem 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  &.btn-primary{
    background-color: $color-primary !important;
  }
  &.rounded{
    border-radius: 15px !important;
  }
  &.min-w-75px{
    min-width: 75px;
  }
}
