@import "variables";
.form-wrapper{
  width: 100%;
}
.input-group-icon{
  position: relative;
  svg{
    position: absolute;
    top: 1rem;
  }
  .icon{
    left: 0.75rem;
  }
  &.icon-right{
    .icon{
      right: 0.75rem;
      left: unset;
    }
  }
}
form{

}

.input-group-icon{
  svg.icon{
    fill:#9E9E9E;
  }
}
select.form-control, input.form-control{
  background-color: #F5F5F5;
  color: #9E9E9E;
  border-color: transparent;
  padding: 0.75rem 0.75rem;
  font-weight: bold;
  border-radius: 0.75rem;
  @media screen and (max-width: 500px) {
    font-size: 1rem;
  }
  &:focus{
    background-color: #EFF3FF;
    border-color: $color-primary;
    box-shadow: none;
  }
}
input.form-control:focus + svg.icon{
  fill:$color-primary;
}
input.form-control:not(:placeholder-shown) {
  color: $color-dark-form;
}
input.form-control:not(:placeholder-shown):not(:focus) + svg.icon { fill: $color-dark-form}
.input-group-icon{
  input.form-control{
    padding-left: calc(0.75rem + 1.25rem + 0.5rem);
  }
  &.icon-right{
    input.form-control{
      padding-left: 0.75rem;
      padding-right: calc(0.75rem + 1.25rem + 0.5rem);
    }
  }

}
