@import "../../../../styles/variables";
.quiz-container{
  padding-bottom: 170px;
}
.question-answer-wrapper{
  .options-wrapper{
    .options-item{
      padding: 1rem;
      border-radius: 8px;
      .opt-index{
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid $color-primary;
        color: $color-primary;
        font-weight: bold;
        width: 40px;
        height: 40px;
      }
      .opt-text{
        word-break: break-word;
        width: 100%;
      }
      &.selected{
        background-color: $color-light;
        .opt-index{
          background-color: $color-primary;
          color: $color-white;
        }
      }
    }
  }
  .question-input{
    textarea{
      width: 100%;
      border: 1px solid var(--bs-gray-400);
      border-radius: 8px;
      padding: 8px;
    }
  }
}

.quiz-navigation{
  position:fixed;
  width: 100%;
  min-height: 50px;
  display: flex;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.15);
  bottom:0;
  left: 0;
  background-color: $color-white;
}
