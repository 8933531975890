input.task-file{
  opacity: 0;
}
.task-file{
  position: absolute;
  z-index: 1;
  top: 0;
  padding: 0.5rem;
  border-radius: 50%;
  label{
    display: flex;
    cursor: pointer;
    width: 100%;
    height: 100%;
    margin-top: 0;
    padding: 0;
    svg{
      fill: #ffffff;
    }
  }
}
