@import "variables";
.text-primary{
  color: $color-primary !important;
}
.text-secondary{
  color: $color-secondary !important;
}
.text-bg-primary{
  background-color: $color-primary !important;
}
