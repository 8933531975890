@import "variables";
.icon-wrapper{
  width: 2rem;
  height: 2rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  svg{
    fill: $color-white;
  }
  &.icon-primary{
    background-color: $color-primary;
  }
  &.icon-secondary{
    background-color: $color-secondary;
  }
  &.icon-disabled{
    background-color: #6c757d;
  }
  &.icon-light{
    background-color: $color-light;
    svg{
      fill: $color-primary
    }
  }
  &.icon-success{
    background-color: rgba(var(--bs-success-rgb),.1);
    svg{
      fill: var(--bs-success)
    }
  }
}
