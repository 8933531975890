@import "../../styles/variables";
.profile-avatar{
  .profile-avatar-image{
    width: 120px;
    height: 120px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .avatar-edit{
    position: absolute;
    border: none;
    outline: none;
    outline: 0;
    box-shadow: none;
    right: 0;
    bottom: 0;
    z-index: 0;
    background: none;
  }
  .avatar-file{
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    background: $color-primary;
    padding: 0.5rem;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    label{
      display: flex;
      cursor: pointer;
      width: 100%;
      height: 100%;
      margin-top: 0;
      padding: 0;
      svg{
        fill: #ffffff;
      }
    }
  }
  .avatar-file-remove{
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    background: $color-primary;
    padding: 0.5rem;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border:none;
    svg{
      fill: #ffffff;
    }
  }
}
