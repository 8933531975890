$color-primary: #0336FF;
$color-secondary: #FFCC03;
$color-tertiary: #FFE683;
$color-dark: #252525;
$color-dark-form: #212529;
$color-white: #FFFFFF;
$color-light: #EFF3FF;

$bg-primary: $color-primary;
$bg-secondary: $color-secondary;
$bg-tertiary: $color-tertiary;

$bottom-nav-height: 4rem;

$padding:8px;

$mobile-breakpoint: 767.9px;
$min-desktop: 768px;

