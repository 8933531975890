@import "../../../styles/variables";
.review-item{
  .review-item-header{
    .review-item-action{
      .review-item-rating-label{
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 30px;
        border: 2px solid $color-primary;
        color: $color-primary;
        padding: 0.25rem 0.5rem;
        white-space: nowrap;
        font-size: 0.75rem;
      }
    }
  }
}
